import { Navigate, Route, Routes } from 'react-router-dom';

import { BusinessStatistics } from '../../../pages/BusinessStatistics';
import { CharacteristicsOfTheUser } from '../../../pages/CharacteristicsOfTheUser';
import { ClientProfile } from '../../../pages/ClientProfile';
import { ExternalCustomers } from '../../../pages/ExternalCustomers';
import { CreateQuestion, Faq } from '../../../pages/Faq';
import { InternalCustomers } from '../../../pages/InternalCustomers';
import { LoginPage } from '../../../pages/LoginPage';
import { Maintenance } from '../../../pages/Maintenance';
import {
  Author,
  Channel,
  Classifications,
  Clip,
  ClipCompilation,
  Contexts,
  Contract,
  Counterparties,
  CounterpartyCandidates,
  CreateAuthor,
  CreateChannel,
  CreateClip,
  CreateClipCompilation,
  CreateContract,
  CreateCounterparties,
  CreateFeed,
  CreateFeedGroup,
  CreateOwner,
  CreatePerson,
  CreatePlaylist,
  CreateReader,
  CreateRelatedFeed,
  CreateRubric,
  CreateSource,
  CreateTranslator,
  EditTheme,
  Feed,
  FeedGroups,
  Keywords,
  NotificationsForClients,
  Owner,
  Person,
  PlayList,
  PlaylistCompilation,
  Purchases,
  Rates,
  Reader,
  RegistryRelatedFeeds,
  RelatedFeed,
  RibbonFilters,
  Rubric,
  SemanticField,
  Source,
  SpecialProjects,
  Tag,
  TagDirectory,
  Theme,
  ThemeCatalog,
  Translator,
  BookSeries,
  CreateBookSeries,
  CreateKeyword,
  CreateRates,
  Supermix,
  CreateInfoSection,
  Section,
  CreateSection,
  CreateAutoSection,
  ReportForCounterparty,
  Summary,
  Total,
  AllTransactions,
  Banks,
  EditTag,
} from '../../../pages/Navbar';
import { CreatePlaylistCompilation } from '../../../pages/Navbar/Entity/PlaylistCompilation/CreatePlaylistCompilation';
import { CreateSupermix } from '../../../pages/Navbar/Entity/Supermix';
import { TagsForCompilations } from '../../../pages/Navbar/Entity/TagsForCompilations';
import { EditTagsForCompilations } from '../../../pages/Navbar/Entity/TagsForCompilations/EditTagsForCompilations';
import { NotFound } from '../../../pages/NotFound';
import { PassRecoveryPage } from '../../../pages/PassRecoveryPage';
import { PersonalPage } from '../../../pages/PersonalPage';
import { RegisterPage } from '../../../pages/RegisterPage';
import { TagDirectoryForCompilations } from '../../../pages/TagDirectiryForCompilations';
import { TechnicalHealth } from '../../../pages/TechnicalHealth';
import ThemeOrder from '../../../pages/ThemeOrder/ui/ThemeOrder';
import { PrivateRoute } from '../PrivateRoute';
import { PublicRoute } from '../PublicRoute';
import { Router } from '../routerEnum';
import {
  Statistics,
  Content,
  PlaylistTheme,
  SupermixProfitGeneral,
} from 'pages';
import { License } from 'pages/License';
import { SupermixProfit, SupermixProfitSingle } from 'pages/Navbar/Dashboards';
import { QuestionOrder } from 'pages/QuestionOrder';

export const AppRouter = (): JSX.Element => (
  <Routes>
    <Route element={<PrivateRoute />}>
      <Route path={Router.MainPage} element={<Navigate to={Router.Clip} />} />
      <Route path={Router.PersonalPage} element={<PersonalPage />} />

      {/* CLIPS */}
      <Route path={Router.Clip} element={<Clip />} />
      <Route
        path={`${Router.Clip}/${Router.Create}`}
        element={<CreateClip />}
      />
      <Route
        path={`${Router.Clip}/${Router.Edit}/:id`}
        element={<CreateClip />}
      />

      {/* PLAYLISTS */}
      <Route path={Router.Playlist} element={<PlayList />} />
      <Route
        path={`${Router.Playlist}/${Router.Create}`}
        element={<CreatePlaylist />}
      />
      <Route
        path={`${Router.Playlist}/${Router.Edit}/:id`}
        element={<CreatePlaylist />}
      />

      {/* CHANNELS */}
      <Route path={Router.Channel} element={<Channel />} />
      <Route
        path={`${Router.Channel}/${Router.Create}`}
        element={<CreateChannel />}
      />
      <Route
        path={`${Router.Channel}/${Router.Edit}/:id`}
        element={<CreateChannel />}
      />

      {/* OWNERS */}
      <Route path={Router.Owner} element={<Owner />} />
      <Route
        path={`${Router.Owner}/${Router.Create}`}
        element={<CreateOwner />}
      />
      <Route
        path={`${Router.Owner}/${Router.Edit}/:id`}
        element={<CreateOwner />}
      />

      {/* CLIP COMPILATION */}
      <Route path={Router.ClipCompilation} element={<ClipCompilation />} />
      <Route
        path={`${Router.ClipCompilation}/${Router.Create}`}
        element={<CreateClipCompilation />}
      />
      <Route
        path={`${Router.ClipCompilation}/${Router.Edit}/:id`}
        element={<CreateClipCompilation />}
      />

      {/* PLAYLIST COMPILATION */}
      <Route
        path={Router.PlaylistCompilation}
        element={<PlaylistCompilation />}
      />
      <Route
        path={`${Router.PlaylistCompilation}/${Router.Create}`}
        element={<CreatePlaylistCompilation />}
      />
      <Route
        path={`${Router.PlaylistCompilation}/${Router.Edit}/:id`}
        element={<CreatePlaylistCompilation />}
      />

      {/* SOURCE */}
      <Route path={Router.Source} element={<Source />} />
      <Route
        path={`${Router.Source}/${Router.Create}`}
        element={<CreateSource />}
      />
      <Route
        path={`${Router.Source}/${Router.Edit}/:id`}
        element={<CreateSource />}
      />

      {/* TAG */}
      <Route path={Router.Tag} element={<Tag />} />
      <Route path={Router.TagDirectory} element={<TagDirectory />} />
      <Route path={`${Router.Tag}/${Router.Edit}/:id`} element={<EditTag />} />

      {/* TAG FOR COMPILATIONS*/}
      <Route
        path={Router.TagsForCompilations}
        element={<TagsForCompilations />}
      />
      <Route
        path={`${Router.TagsForCompilations}/${Router.Edit}/:id`}
        element={<EditTagsForCompilations />}
      />

      {/* TAG DIRECTORY FOR COMPILATIONS*/}
      <Route
        path={Router.TagDirectoryForCompilations}
        element={<TagDirectoryForCompilations />}
      />

      {/* THEME */}
      <Route path={Router.Theme} element={<Theme />} />
      <Route
        path={`${Router.Theme}/${Router.Edit}/:id`}
        element={<EditTheme />}
      />
      <Route path={Router.ThemeOrder} element={<ThemeOrder />} />
      <Route path={Router.ThemeCatalog} element={<ThemeCatalog />} />

      {/* AUTHOR */}
      <Route path={Router.Author} element={<Author />} />
      <Route
        path={`${Router.Author}/${Router.Create}`}
        element={<CreateAuthor />}
      />
      <Route
        path={`${Router.Author}/${Router.Edit}/:id`}
        element={<CreateAuthor />}
      />

      {/* TRANSLATOR */}
      <Route path={Router.Translator} element={<Translator />} />
      <Route
        path={`${Router.Translator}/${Router.Create}`}
        element={<CreateTranslator />}
      />
      <Route
        path={`${Router.Translator}/${Router.Edit}/:id`}
        element={<CreateTranslator />}
      />

      {/* READER */}
      <Route path={Router.Reader} element={<Reader />} />
      <Route
        path={`${Router.Reader}/${Router.Create}`}
        element={<CreateReader />}
      />
      <Route
        path={`${Router.Reader}/${Router.Edit}/:id`}
        element={<CreateReader />}
      />

      {/* PERSON */}
      <Route path={Router.Person} element={<Person />} />
      <Route
        path={`${Router.Person}/${Router.Create}`}
        element={<CreatePerson />}
      />
      <Route
        path={`${Router.Person}/${Router.Edit}/:id`}
        element={<CreatePerson />}
      />

      {/* RUBRIC */}
      <Route path={Router.Rubric} element={<Rubric />} />
      <Route
        path={`${Router.Rubric}/${Router.Create}`}
        element={<CreateRubric />}
      />
      <Route
        path={`${Router.Rubric}/${Router.Edit}/:id`}
        element={<CreateRubric />}
      />

      <Route path={Router.Classifications} element={<Classifications />} />
      <Route path={Router.SemanticField} element={<SemanticField />} />

      {/* SUPERMIX */}
      <Route path={Router.Supermix} element={<Supermix />} />
      <Route
        path={`${Router.Supermix}/${Router.Create}`}
        element={<CreateSupermix />}
      />
      <Route
        path={`${Router.Supermix}/${Router.Edit}/:id`}
        element={<CreateSupermix />}
      />

      {/* BOOKSERIES */}
      <Route path={Router.BookSeries} element={<BookSeries />} />
      <Route
        path={`${Router.BookSeries}/${Router.Create}`}
        element={<CreateBookSeries />}
      />
      <Route
        path={`${Router.BookSeries}/${Router.Edit}/:id`}
        element={<CreateBookSeries />}
      />

      {/* KEYWORDS */}
      <Route path={Router.Keywords} element={<Keywords />} />
      <Route
        path={`${Router.Keywords}/${Router.Create}`}
        element={<CreateKeyword />}
      />
      <Route
        path={`${Router.Keywords}/${Router.Edit}/:id`}
        element={<CreateKeyword />}
      />

      {/* FEEDS */}
      <Route path={Router.Feed} element={<Feed />} />
      <Route
        path={`${Router.Feed}/${Router.Create}`}
        element={<CreateFeed />}
      />
      <Route
        path={`${Router.Feed}/${Router.Edit}/:id`}
        element={<CreateFeed />}
      />

      {/* FEEDS GROUPS */}
      <Route path={Router.FeedsGroups} element={<FeedGroups />} />
      <Route
        path={`${Router.FeedsGroups}/${Router.Create}`}
        element={<CreateFeedGroup />}
      />
      <Route
        path={`${Router.FeedsGroups}/${Router.Edit}/:id`}
        element={<CreateFeedGroup />}
      />

      {/* RELATED FEEDS */}
      <Route
        path={Router.RegistryRelatedFeeds}
        element={<RegistryRelatedFeeds />}
      />
      <Route path={Router.RelatedFeed} element={<RelatedFeed />} />
      <Route
        path={`${Router.RelatedFeed}/${Router.Create}`}
        element={<CreateRelatedFeed />}
      />
      <Route
        path={`${Router.RelatedFeed}/${Router.Edit}/:id`}
        element={<CreateRelatedFeed />}
      />

      <Route path={Router.RibbonFilters} element={<RibbonFilters />} />
      <Route path={Router.Contexts} element={<Contexts />} />
      <Route path={Router.ClientProfile} element={<ClientProfile />} />
      <Route path={Router.ExternalCustomers} element={<ExternalCustomers />} />
      <Route path={Router.InternalCustomers} element={<InternalCustomers />} />
      <Route
        path={Router.CharacteristicsOfTheUser}
        element={<CharacteristicsOfTheUser />}
      />

      {/* RATES */}
      <Route path={Router.Rates} element={<Rates />} />
      <Route
        path={`${Router.Rates}/${Router.Create}`}
        element={<CreateRates />}
      />
      <Route
        path={`${Router.Rates}/${Router.Edit}/:id`}
        element={<CreateRates />}
      />

      {/* PURCHASES */}
      <Route path={Router.Purchases} element={<Purchases />} />

      {/* CONTRACTS */}
      <Route path={Router.Contract} element={<Contract />} />
      <Route
        path={`${Router.Contract}/${Router.Create}`}
        element={<CreateContract />}
      />
      <Route
        path={`${Router.Contract}/${Router.Edit}/:id`}
        element={<CreateContract />}
      />

      {/* COUNTERPARTIES */}
      <Route path={Router.Counterparties} element={<Counterparties />} />
      <Route
        path={`${Router.Counterparties}/${Router.Create}`}
        element={<CreateCounterparties />}
      />
      <Route
        path={`${Router.Counterparties}/${Router.Edit}/:id`}
        element={<CreateCounterparties />}
      />
      <Route
        path={`${Router.Counterparties}/${Router.Edit}/:id`}
        element={<CreateCounterparties />}
      />
      <Route
        path={Router.CounterpartyCandidates}
        element={<CounterpartyCandidates />}
      />

      <Route
        path={Router.NotificationsForClients}
        element={<NotificationsForClients />}
      />

      <Route path={Router.SpecialProjects} element={<SpecialProjects />} />

      <Route path={Router.BusinessStatistics}>
        <Route index={true} element={<BusinessStatistics />} />
        <Route
          path={`${Router.ReportForCounterparty}`}
          element={<ReportForCounterparty />}
        />
        <Route path={`${Router.SupermixProfit}`}>
          <Route index={true} element={<SupermixProfit />} />
          <Route path="general" element={<SupermixProfitGeneral />} />
          <Route path="single" element={<SupermixProfitSingle />} />
        </Route>

        <Route path={`${Router.Statistics}`}>
          <Route index={true} element={<Statistics />} />
          <Route path={`${Router.Content}`}>
            <Route index={true} element={<Content />} />
            <Route
              element={<PlaylistTheme />}
              path={`${Router.PlaylistTheme}`}
            />
          </Route>
        </Route>

        <Route path={Router.AllTransactions}>
          <Route index={true} element={<AllTransactions />} />

          <Route path={`${Router.Summary}`} element={<Summary />} />
          <Route path={`${Router.Total}`} element={<Total />} />
          <Route path={`${Router.Banks}`} element={<Banks />} />
        </Route>
      </Route>

      <Route path={Router.TechnicalHealth}>
        <Route index={true} element={<TechnicalHealth />} />
        <Route path={`${Router.License}`} element={<License />} />
      </Route>

      {/* FAQ */}
      <Route path={Router.FAQ} element={<Faq />} />
      <Route
        path={`${Router.FAQ}/${Router.Create}`}
        element={<CreateQuestion />}
      />
      <Route
        path={`${Router.FAQ}/${Router.Edit}/:id`}
        element={<CreateQuestion />}
      />

      {/* QuestionOrder */}
      <Route path={Router.QuestionOrder} element={<QuestionOrder />} />
      <Route
        path={`${Router.FAQ}/${Router.Create}`}
        element={<CreateQuestion />}
      />
      <Route
        path={`${Router.FAQ}/${Router.Edit}/:id`}
        element={<CreateQuestion />}
      />

      {/* SECTIONS */}
      <Route path={Router.Section} element={<Section />} />

      <Route
        path={`${Router.Section}/base/${Router.Create}`}
        element={<CreateSection />}
      />

      <Route
        path={`${Router.Section}/base/${Router.Edit}/:id`}
        element={<CreateSection />}
      />

      {/* INFO SECTION */}
      <Route
        path={`${Router.Section}/info/${Router.Create}`}
        element={<CreateInfoSection />}
      />

      <Route
        path={`${Router.Section}/info/${Router.Edit}/:id`}
        element={<CreateInfoSection />}
      />

      {/* AUTO SECTION*/}
      <Route
        path={`${Router.Section}/auto/${Router.Create}`}
        element={<CreateAutoSection />}
      />

      <Route
        path={`${Router.Section}/auto/${Router.Edit}/:id`}
        element={<CreateAutoSection />}
      />
    </Route>

    <Route element={<PublicRoute />}>
      <Route path={Router.Login} element={<LoginPage />} />
      <Route path={Router.Register} element={<RegisterPage />} />
      <Route path={Router.PassRecovery} element={<PassRecoveryPage />} />
    </Route>

    <Route path="*" element={<NotFound />} />

    {/* MAINTENANCE */}
    <Route path={`${Router.Maintenance}`} element={<Maintenance />} />
  </Routes>
);
