import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useGetMaintenanceQuery } from 'app/api/services/maintenance/maintenance';
import { Loader } from 'widgets';

interface IProps {
  children?: ReactNode;
}

export const MaintenanceProvider = ({ children }: IProps): JSX.Element => {
  const { data, isLoading } = useGetMaintenanceQuery({});

  const navigation = useNavigate();
  const location = useLocation();

  if (isLoading) {
    return <Loader />;
  }

  if (location.pathname !== '/maintenance' && data?.enabled) {
    navigation('/maintenance');
  }

  if (location.pathname === '/maintenance' && !data?.enabled) {
    navigation(-1);
  }

  return <>{children}</>;
};
