import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Controls } from './Controls';
import {
  DownloadSpreadsheet,
  ErrorAlert,
  IfraimeTable,
  Loader,
} from '../../../../../widgets';
import {
  initialValues,
  ISupermixProfitValues,
  validateGetSupermixProfit,
} from '../helpers';
import {
  useGetSupermixDetailsFiltersQuery,
  useLazyGetSupermixDetailsQuery,
} from 'app/api/services/reportsSupermix';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { getLinkForIFraimeTable } from 'utils/getLinkForIFraimeTable';

import style from './SupermixProfitSingle.module.scss';

export const SupermixProfitSingle = (): JSX.Element => {
  const [downloadFile, isFileDownloading] = useDownloadFile();
  const [isValidateCheked, setIsValidateCheked] = useState(false);

  const {
    data: filters,
    isLoading: isLoadingFilters,
    isError: isErrorFilters,
  } = useGetSupermixDetailsFiltersQuery();

  const [
    getDetails,
    {
      data,
      isLoading: isLoadingStats,
      isFetching: isFetchingDetails,
      isError: isErrorDetails,
    },
  ] = useLazyGetSupermixDetailsQuery();

  const supermixes = filters?.response?.map((el) => ({
    id: el.id,
    name: el.name,
  }));

  const handleGetStats = async (values: ISupermixProfitValues) => {
    await getDetails({
      month: format(new Date(values.month), 'yyyy-MM', {
        locale: ru,
      }),
      owner_id: values.owner.id,
      supermix_id: values.supermix_id,
    });
  };

  useEffect(() => {
    if (isErrorDetails || isErrorFilters) {
      toast.error('Что-то пошло не так.');
    }
  }, [isErrorDetails, isErrorFilters]);

  if (isLoadingFilters) return <Loader />;

  if (filters?.response)
    return (
      <div className={style.wrapper}>
        <div className={style.header}>
          <div className={style.left}>
            <h4 className={style.title}>Поштучный расчёт</h4>
            <Formik
              initialValues={initialValues}
              validate={validateGetSupermixProfit}
              validateOnBlur={isValidateCheked}
              validateOnChange={isValidateCheked}
              onSubmit={handleGetStats}
            >
              {(formik) => {
                if (Object.keys(formik.errors).length) {
                  setIsValidateCheked(true);
                }

                return (
                  <Form
                    className={style.controls}
                    onKeyDown={(event) =>
                      event.key === 'Enter' && event.preventDefault()
                    }
                  >
                    <Controls supermixes={supermixes} filters={filters} />
                    <DownloadSpreadsheet
                      disabled={!data?.response?.download_url}
                      onClick={() =>
                        downloadFile(
                          data?.response?.download_url,
                          'xlsx',
                          'point-by-point-calculation-system'
                        )
                      }
                    />

                    {Object.keys(formik.errors).length > 0 && (
                      <ErrorAlert text="Пожалуйста, заполните все обязательные поля" />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        {(isFileDownloading || isFetchingDetails) && <Loader />}
        {isLoadingStats ? (
          <Loader />
        ) : (
          data?.response && (
            <>
              <div className={style.tables}>
                <div className={style.table}>
                  <h2 className={style.tableTitle}>
                    Посекундная система расчёта
                  </h2>
                  <IfraimeTable
                    url={getLinkForIFraimeTable(
                      data?.response?.view_url,
                      data?.response?.gids[1]
                    )}
                    width={'633px'}
                    height={'552px'}
                  />
                </div>
                <div className={style.table}>
                  <h2 className={style.tableTitle}>
                    Побальная система расчёта
                  </h2>
                  <IfraimeTable
                    url={getLinkForIFraimeTable(
                      data?.response?.view_url,
                      data?.response?.gids[0]
                    )}
                    width={'633px'}
                    height={'552px'}
                  />
                </div>
              </div>
            </>
          )
        )}
      </div>
    );
};
