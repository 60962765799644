import { endpoints } from '../../../../shared/api/endpoints/endpoints';
import { mainApi } from '../config';

export const mainApiQuery = mainApi.injectEndpoints({
  endpoints: (builder) => ({
    getMaintenance: builder.query({
      query: () => ({
        url: `${endpoints.maintenance}`,
      }),
    }),
  }),
});

export const { useGetMaintenanceQuery } = mainApiQuery;
