import React, { SVGProps } from 'react';

type SvgProps = SVGProps<SVGSVGElement>;

export const GearIcon = (props: SvgProps): JSX.Element => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_13276_4)">
      <path
        d="M8.65328 22L8.19778 18.4653C7.79597 18.3472 7.36185 18.1622 6.89545 17.9104C6.4282 17.6579 6.031 17.3873 5.70384 17.0989L2.34672 18.4861L0 14.5139L2.90162 12.3884C2.86388 12.1684 2.83326 11.9411 2.80977 11.7064C2.7846 11.4718 2.77202 11.244 2.77202 11.0232C2.77202 10.8179 2.7846 10.602 2.80977 10.3754C2.83326 10.1489 2.86388 9.89389 2.90162 9.61033L0 7.48733L2.34672 3.56156L5.67868 4.92556C6.05449 4.62163 6.46134 4.34744 6.89922 4.103C7.33543 3.85856 7.76031 3.66952 8.17387 3.53589L8.65202 0H13.348L13.8022 3.55911C14.2846 3.7237 14.7107 3.91233 15.0806 4.125C15.4506 4.33767 15.8318 4.60411 16.2244 4.92433L19.6533 3.56156L22 7.48611L19.0027 9.68122C19.0715 9.93381 19.1101 10.1656 19.1185 10.3767C19.1269 10.5877 19.1311 10.7955 19.1311 11C19.1311 11.1899 19.1227 11.3899 19.1059 11.6001C19.09 11.8111 19.0531 12.0662 18.9952 12.3652L21.9446 14.5139L19.5979 18.4861L16.2244 17.0757C15.8327 17.3959 15.4384 17.6701 15.0416 17.8982C14.6449 18.1264 14.2317 18.3077 13.8022 18.4421L13.348 22H8.65328ZM10.966 14.0556C11.8435 14.0556 12.5871 13.7594 13.197 13.167C13.8068 12.5746 14.1118 11.8523 14.1118 11C14.1118 10.1477 13.8068 9.42537 13.197 8.833C12.5871 8.24063 11.8435 7.94444 10.966 7.94444C10.0835 7.94444 9.33863 8.24063 8.7313 8.833C8.12396 9.42537 7.82029 10.1477 7.82029 11C7.82029 11.8523 8.12396 12.5746 8.7313 13.167C9.33863 13.7594 10.0835 14.0556 10.966 14.0556Z"
        fill="#434343"
      />
    </g>
    <defs>
      <clipPath id="clip0_13276_4">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
